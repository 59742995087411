.imageGallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white; /* 默认背景色 */
  transition: background-color 0.3s ease;
}

.nightMode {
  background-color: black; /* 夜晚模式背景色 */
}

.buttonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.toggleButton, .refreshButton {
  background: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.toggleButton img, .refreshButton img {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.imageItem {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 100%;
}

.lazyImage {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.lazyImage[src] {
  opacity: 1;
}

.loading {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #999;
}
