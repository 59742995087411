/* AddFriendForm.css */
.addFriendForm {
  display: flex;
  align-items: center;
  gap: 10px; /* 间距 */
}

.searchInput {
  flex-grow: 1; /* 使输入框占据剩余空间 */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.addButton {
  padding: 5px 5px;
  /*background-color: #007bff;*/
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.addButton:hover {
  background-color: #0056b3;
}
