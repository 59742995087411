/* ChatWindow.module.css */
.chatWindow {
    display: flex;
    flex-direction: column;
    height: 100%; /* 确保高度为 100% */
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.messageList {
    flex-grow: 1;
    min-height: 600px; /* 设置最小高度 */
    overflow-y: auto;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.message {
    margin: 5px 0;
    padding: 5px;
    background-color: #f4f4f4;
    border-radius: 5px;
}

.contentWrapper {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    word-break: break-word; /* 防止长单词溢出 */
    position: relative;
}

.selfMessage  .contentWrapper {
    justify-content: flex-end;
    background-color: #d4edda;
    color: #155724;
}

.otherMessage .contentWrapper {
    justify-content: flex-start;
    background-color: #f4f4f4;
    color: #333;
}

.sender {
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
}

.content {
    margin-left: 5px;
    margin-right: 5px;
    padding: 8px 12px;
    position: relative;
}
/* 添加指向箭头 */
.content::before {
    font-weight: bold;
    content: '';
    position: absolute;
    top: 12px;
    border-width: 6px;
    border-style: solid;
    z-index: 1;
}
.selfMessage .content::before {
    left: 95%;
    border-color: transparent #1aad19 transparent transparent;
}

.otherMessage .content::before {
    right: 95%;
    border-color: transparent transparent transparent #1aad19;
}

.inputContainer {
    display: flex;
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ccc;
}

.inputContainer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    height: 40px;
}

.inputContainer button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
}

.inputContainer button:hover {
    background-color: #0056b3;
}