.drawingBoard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    overflow: hidden;
}

.canvasWrapper {
    position: relative;
    width: 100%;
    flex: 1;
    min-height: 0; /* 防止溢出 */
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: none; /* 防止移动端的默认触摸行为 */
}

.drawingCanvas {
    flex: 1;
    position: relative; /* 确保画布在正确的层级 */
    touch-action: none;
    background: #fff;
    cursor: crosshair;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 100%;
    max-height: 100%;
    pointer-events: auto; /* 确保画布可以接收事件 */
    /* 确保画布不会被拉伸 */
    /*image-rendering: -moz-crisp-edges;*/
    /*image-rendering: -webkit-crisp-edges;*/
    /*image-rendering: pixelated;*/
    /*image-rendering: crisp-edges;*/
}

.toolbarContainer {
    position: relative;
    z-index: 100;
}


/* 移动端样式调整 */
@media (max-width: 768px) {
    
    .toolbarContainer {
        padding: 4px;
    }
}

.resizeHandle {
    width: 10px; /* 手柄的宽度 */
    height: 10px; /* 手柄的高度 */
    background-color: red; /* 手柄的颜色 */
    position: absolute; /* 绝对定位 */
    right: 0; /* 右侧对齐 */
    bottom: 0; /* 底部对齐 */
    cursor: nwse-resize; /* 鼠标悬停时显示调整大小的光标 */
}