
/* src/components/page/anv/Navigation.module.css */
.navFormContainer {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    /*width: 300px;*/
    /*max-width: 400px;*/
}


.closeButton {
    /*position: absolute;*/
    top: 2px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
    color: #6c757d;
    align-self: flex-end; /* 将按钮靠右对齐 */
}

.navLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* 间距 */
    /*border: 1px solid #6c757d;*/
    /*border-radius: 8px;*/
    font-size: 17px;
    min-height: 200px;
    min-width: 200px;
    /*max-height: 900px;*/
    max-width: 500px;
    overflow: hidden;
    /*overflow-y: auto;*/
}

.navLink {
    /*width: 50px;*/
    height: 30px;
    /*background-color: #f0f0f0;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    border-radius: 8px; /* 圆角 */
    padding: 2px;
}

.navLink:hover {
    background-color: #d0d0d0; /* 鼠标悬停时的背景颜色 */
    color: black;
    transform: scale(1.05); /* 放大效果 */
}

.navLink:active {
    background-color: #a0a0a0; /* 鼠标点击时的背景颜色 */
    transform: scale(1); /* 缩小效果 */
}

.navLink:focus {
    outline: none; /* 移除默认的焦点轮廓 */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* 焦点时的阴影效果 */
}