/* FriendList.module.css */
.friendList {
    padding: 10px;
    background-color: #f4f4f4;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.friendList ul {
    list-style: none;
    padding: 0;
}

.friendItem {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.friendItem:hover {
    background-color: #e0e0e0;
}

.friendItem.selected {
    background-color: #d1e7dd;
    font-weight: bold;
}