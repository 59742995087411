.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #E3F2FD, #FFF9E5); /* 渐变背景 */

}

.inputGroup {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  max-width: 580px;
}

.inputBox {
  flex: 1;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  padding: 0 10px;
  font-size: 16px;
  min-width: 200px;
}

.buttonBox {
  margin-left: 10px;
  width: 80px;
  height: 40px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.fullscreenButton {
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.card {
  width: 330px;
  height: 550px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.iframeView {
  width: 100%;
  height: 100%;
  border: none;
  transition: opacity 0.3s ease;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  cursor: pointer;
}

.download {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.loadingIcon {
  width: 30px; /* 根据需要调整宽度 */
  height: 30px; /* 根据需要调整高度 */
  vertical-align: middle; /* 垂直对齐 */
}