/* src/components/Register.module.css */
.registerFormContainer {
    /*position: fixed;*/
    /*background-color: rgba(255, 255, 255, 0.1);*/
    padding: 10px;
    border: 1px solid #6c757d; /* 初始灰色边框 */
    border-radius: 5px;
    width: 300px;
    max-width: 400px;
    /*width: 90%;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px; /* 设置最小高度 */
}

.closeButton {
    /*position: absolute;*/
    top: 2px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
    color: #6c757d;
    align-self: flex-end; /* 将按钮靠右对齐 */
}

.inputField {
    display: block;
    /*width: 90%;*/
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.fileInput {
    margin-bottom: 10px;
    display: none; /* 隐藏默认文件输入框 */
}

.customFileInput {
    width: 100%;
    height: 40px;
    display: block;
    color: white;
    border: 1px solid #6c757d;
    border-radius: 4px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.3s ease;
    text-align: center; /* 文字居中 */
    margin: 0 auto 10px; /* 水平居中 */
}

.customFileInput:hover {
    box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.2); /* 更柔和的发光效果 */
}

.avatarPreview {
    display: block;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 0 auto 10px; /* 居中显示 */
    border-radius: 50%;
}

.submitButton {
    /*display: block;*/
    width: 100%;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 1; /* 默认不透明度 */
}

.submitButton:hover {
    background-color: #004b9e;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2); /* 更柔和的发光效果 */
}
.submitButton:disabled {
    background-color: #007bff; /* 加载时的背景颜色 */
    opacity: 0.6; /* 加载时的透明度 */
    cursor: not-allowed; /* 禁用状态的光标 */
}

.submitButton:hover:not(:disabled) {
    background-color: #004b9e;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2); /* 更柔和的发光效果 */
}
.error {
    color: red;
    font-size: 0.8em;
    margin-top: 1px;
}

.weChatButton {
    /*width: calc(50% - 5px);*/
    width: 40%;
    padding: 10px;
    background-color: #1aad19;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.weChatButton:hover {
    background-color: #117c11;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2); /* 更柔和的发光效果 */
}

.buttonRow {
    display: flex;
    /* justify-content: space-between; */
}


.qrCodeModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.qrCodeContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.qrCodeImage {
    max-width: 100%;
    max-height: 100%;
}

.loadingIcon {
    width: 30px; /* 根据需要调整宽度 */
    height: 30px; /* 根据需要调整高度 */
    vertical-align: middle; /* 垂直对齐 */
  }