
/* src/components/PixelAvatarsModal.css */
.modalOverlay {
    /*position: fixed;*/
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */

}

.modalContent {
    /*padding: 20px;*/
    border-radius: 5px;
    /*margin-top: 20px;*/
    /*width: 80%; !* 弹窗宽度 *!*/
    /*height: 80%; !* 弹窗高度 *!*/
    display: flex;
    flex-direction: column;
    align-items: center;
    /*border: 1px solid #6c757d; !* 初始灰色边框 *!*/
    width: 100%;
}

.closeButton {
    top: 2px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
    color: #6c757d;
    align-self: flex-end;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.2); /* 更柔和的发光效果 */
}

.avatarsContainer {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2px; /* 头像之间的间隙 */
    /*margin-top: 20px;*/
    justify-content: center; 
    align-items: center;
}

.avatar {
    width: 20px; /* 头像宽度 */
    height: 20px; /* 头像高度 */
    border-radius: 5%; /* 圆形头像 */
    object-fit: cover; /* 裁剪并填充头像 */
}


.zoomedImageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 8px;
}

.zoomedImage {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
}