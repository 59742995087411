.stickerPanel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #ffffff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 60vh;
    overflow-y: auto;
    transition: transform 0.3s ease;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
}

.header h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
}

.closeButton {
    background: none;
    border: none;
    font-size: 24px;
    color: #999;
    cursor: pointer;
    padding: 0 8px;
}

.closeButton:hover {
    color: #666;
}

.searchBar {
    display: flex; /* 使用 Flexbox 布局 */
    align-items: center; /* 垂直居中对齐 */
    margin-bottom: 16px; /* 添加底部间距 */
}

.searchUploadContainer {
    display: flex; /* 使用 Flexbox 布局 */
    align-items: center; /* 垂直居中对齐 */
    width: 100%; /* 使容器宽度为 100% */
}

.searchInput {
    flex: 1; /* 使搜索框占据剩余空间 */
    padding: 8px; /* 添加内边距 */
    border: 1px solid #ddd; /* 边框样式 */
    border-radius: 4px; /* 圆角 */
    margin-right: 8px; /* 右侧间距 */
}

.searchInput:focus {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.uploadButton {
    background-color: #1890ff; /* 按钮背景色 */
    color: white; /* 按钮文字颜色 */
    padding: 8px 12px; /* 按钮内边距 */
    border: none; /* 无边框 */
    border-radius: 4px; /* 圆角 */
    cursor: pointer; /* 鼠标悬停时显示手型 */
}

.tabList {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #f0f0f0;
}

.tab {
    flex: 1;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    color: #666;
    transition: all 0.3s;
}

.tab:hover {
    color: #1890ff;
}

.tab[aria-selected='true'] {
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
}

.stickerGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 12px;
    padding: 16px;
}

.stickerItem {
    aspect-ratio: 1;
    padding: 8px;
    border: 1px solid #eee;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* 使手柄相对于贴纸定位 */
}

.stickerItem:hover {
    transform: scale(1.05);
    border-color: #1890ff;
    box-shadow: 0 2px 8px rgba(24, 144, 255, 0.1);
}

.stickerItem img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* pointer-events: none; */
}


.noResults {
    padding: 24px;
    text-align: center;
    color: #999;
}

/* 移动端适配 */
@media (max-width: 768px) {
    .stickerPanel {
        max-height: 70vh;
    }

    .stickerGrid {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
        gap: 8px;
        padding: 12px;
    }

    .header h3 {
        font-size: 16px;
    }

    .tab {
        padding: 8px;
        font-size: 14px;
    }
}