.emojiSelector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.emoji {
    width: 30px;
    height: 30px;
    cursor: pointer;
}