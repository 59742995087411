.toolbar {
    display: flex;
    gap: 12px;
    padding: 8px;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.toolGroup {
    display: flex;
    gap: 4px;
    padding: 0 8px;
    border-right: 1px solid #eee;
}

.toolGroup:last-child {
    border-right: none;
}

.toolButton {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    background: transparent;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;
}

.toolButton:hover {
    background: #f5f5f5;
    color: #1890ff;
}

.toolButton.active {
    background: #e6f7ff;
    color: #1890ff;
}

.toolButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.toolButton svg {
    font-size: 18px;
}

.toolButton span {
    font-size: 14px;
}

@media (max-width: 768px) {
    .toolbar {
        padding: 4px;
        gap: 4px;
    }

    .toolGroup {
        padding: 0 4px;
    }

    .toolButton {
        padding: 6px;
    }

    .toolButton svg {
        font-size: 16px;
    }
}