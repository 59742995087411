.colorPicker {
    position: absolute;
    top: 100%;
    left: 0;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    width: 280px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.header h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.header button {
    background: none;
    border: none;
    font-size: 20px;
    color: #999;
    cursor: pointer;
}

.presetColors {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    margin-bottom: 16px;
}

.colorButton {
    width: 100%;
    aspect-ratio: 1;
    border: 2px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 0 0 1px #ddd;
    transition: transform 0.2s;
}

.colorButton:hover {
    transform: scale(1.1);
}

.colorButton.active {
    border-color: #1890ff;
}

.customColor {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.colorInput {
    width: 100%;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.customColor span {
    font-size: 14px;
    color: #666;
}