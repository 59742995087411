/* styles.css */
.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box; /* 使用边框盒模型 */
    /*background-color: rgba(0, 0, 0, 0); !* 设置背景为完全透明 *!*/
    background-color: transparent;  /* 设置背景为透明 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
    position: absolute;
    width: 100%; /* 确保 header 占满整个宽度 */
    padding: 10px; /* 添加内边距 */
}

.header button {
    height: 40px;
    width: 40px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;/* 按钮背景透明 */
    color: white;
    border-color: rgba(255, 255, 255, 0.3); /* 边框颜色变为透明 */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
    margin-left: 10px;
}

.header button:hover {
    border-color: rgba(255, 255, 255, 0.5); /* 半透明的灰色边框 */
    color: white; /* 文字颜色变为白色 */
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5); /* 更柔和的发光效果 */
}

.menuButton {
    /*border: 1px solid rgba(255, 255, 255, 0.3);*/
    background-image: url('../../static/menu.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.toggleButton {
    /*padding: 10px 20px;*/
    /*font-size: 12px;*/
    /*cursor: pointer;*/
    border: 1px solid rgba(255, 255, 255, 0.3);
    /*border-radius: 4px;*/
    /*background-color: #070707;*/
    /*color: white;*/
    /*transition: background-color 0.3s;*/
    background-image: url('../../static/pixel.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.registerButton {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-image: url('../../static/register.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*right: 1px;*/
    /*padding: 10px 20px;*/
    /*font-size: 12px;*/
    /*cursor: pointer;*/
    /*border: none;*/
    /*border-radius: 4px;*/
    /*background-color: #070707;*/
    /*color: white;*/
    /*transition: background-color 0.3s;*/
}

