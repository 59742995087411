/* MusicPlayer.module.css */
.mPlayer {
    position: relative;
    width: 60px;
    height: 60px;
    /*border-radius: 50%;*/
    /*background-color: #ccc;*/
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*border: 1px solid #ffffff;*/
    transition: width 0.5s ease-in-out;
}

.cdIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*transform: translate(-50%, -50%) scale(0.1); !* 缩放比例 *!*/
    transition: opacity 0.5s ease-in-out;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0);
}

.cdIcon:hover {
    border-color: rgba(255, 255, 255, 0.5); /* 半透明的灰色边框 */
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5); /* 更柔和的发光效果 */
}

.rotating {
    animation: rotate 10s linear infinite; /* 动画名称、持续时间、过渡效果、循环次数 */
}

.static {
    animation: none;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.cdNeedle {
    position: absolute;
    top: 30%;
    left: 80%;
    transform: translate(-50%, -50%) scale(0.3); /* 缩放比例 */
    transition: opacity 0.5s ease-in-out;
}

.visible {
    visibility: visible;
    opacity: 1;
    animation: fadeIn 1s ease-in-out;
}
/* 动画效果 */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.invisible {
    visibility: hidden;
}

.expandedPlayer {
    flex-direction: row; /* 向右展开 */
    align-items: center;
    margin-left: 300px;
    transition: width 0.5s ease-in-out;
    width: 300px; /* 默认宽度 */
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    z-index: 100;
    /*background-color: rgb(255, 255, 255);*/
}

/*.expandedPlayer.expandedLyrics {*/
/*    width: 1000px; !* 展开歌词后的宽度 *!*/
/*}*/

.expandedPlayerControls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

}

.expandedPlayerControls button {
    height: 30px;
    width: 30px;
    cursor: pointer;
    align-items: center;
    border-radius: 50px;
    /*background-color: rgb(255, 255, 255);*/
}
.prevButton {
    background-image: url('../../../static/left.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.playButton {
    background-image: url('../../../static/play.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*border-radius: 50px;*/
    /*transform:  scale(1.5);*/
}

.pauseButton {
    background-image: url('../../../static/stop.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*border-radius: 50px;*/
    /*transform:  scale(2);*/
}

.nextButton {
    background-image: url('../../../static/right.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
/*.lyricsSwitch {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: 100%;*/
/*    margin-top: 10px;*/
/*}*/

/*.lyrics {*/
/*    flex-direction: row; !* 向右展开 *!*/
/*    align-items: center;*/
/*    transition: width 0.5s ease-in-out;*/
/*    width: 200px; !* 默认宽度 *!*/
/*    border: 1px solid;*/
/*    border-radius: 10px;*/
/*}*/

.glassAudioPlayer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.49);
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.glassAudioPlayer audio {
    height: 20px;
    width: 200px;
    border: none;
    outline: none;
    background: transparent; /* 设置背景为透明 */
    color: transparent; /* 设置文字颜色为透明 */
    font-size: 16px;
    border-radius: 5px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}


/*用于设置整个音频控件的样式，例如背景颜色、文本颜色和圆角*/
.glassAudioPlayer audio::-webkit-media-controls-panel{
    background-color: #6c757d;
}
/* 更多选项”按钮，通常显示为一个有三个垂直点的按钮和播放按钮 */
.glassAudioPlayer audio::-webkit-media-controls-play-button,
.glassAudioPlayer audio::-webkit-media-controls-overflow-button {
    /*overflow:hidden;*/
    display: none !important;
}

