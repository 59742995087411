/* src/components/Content.module.css */
.contentContainer {
    box-sizing: border-box; /* 使用边框盒模型 */
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    padding: 10px 20px;
    background-color: transparent;  /* 设置背景为透明 */
    position: absolute;
    text-align: center;
    width: 100%; /* 宽度为100% */
    /*height: 100vh; */
    left: 0; /* 确保从左侧开始 */
    margin-top: 40px;
    z-index: 1;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* 标题和时钟之间的间距 */
}

.title {
    color: #808080; /* 深灰色 */
    text-shadow: 0 0 10px rgba(51, 51, 51, 0.5); /* 微妙的发光效果 */
    /* margin: 0; */
    font-size: 1.5rem;
}

.clock {
    font-size: 2rem; /* 时钟字体大小 */
    font-family: monospace; /* 等宽字体，使数字对齐 */
    color: #C0C0C0; /* 深灰色 */
    text-shadow: 0 0 10px rgba(51, 51, 51, 0.5);
    /* background-color: rgba(0, 0, 0, 0.2);  */
    /* padding: 10px 20px; */
    border-radius: 10px;
    /* backdrop-filter: blur(5px); */
}

.weather {
    font-size: 1.2rem;
    color: #A9A9A9;
    text-shadow: 0 0 10px rgba(51, 51, 51, 0.5);
    /* padding: 5px 15px; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}