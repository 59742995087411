/* ChatMain.module.css */
.chatMain {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  max-height: 800px;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.functionBar {
  width: 60px;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.sidebar {
  display: flex;
  width: 300px;
  min-width: 100px;
  border-right: 1px solid #ccc;
  padding: 10px;
  background-color: #f4f4f4;
  flex-direction: column;
}

.mainContent {
  flex-grow: 1;
  padding: 10px;
  background-color: #fff;
  display: flex;
  /*height: 100%;*/
}

.chatWindowContainer {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /*max-height: 600px; !* 设置最大高度 *!*/
  width: 100%; /* 适应内容宽度 */
  height: 100%; /* 适应内容高度 */
  /*overflow: auto; !* 内容超出时显示滚动条 *!*/
}