/* App.css */
body,
html,
#root,
.App {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* 防止出现滚动条 */
  flex-direction: column; /* 垂直排列 */
  align-items: center; /* 水平居中 */
  justify-content: center; /* 垂直居中 */
  font-family: Noto Sans SC, sans-serif;
}
