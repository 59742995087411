.brushSelector {
    position: absolute;
    top: 100%;
    left: 0;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    width: 240px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.header h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.header button {
    background: none;
    border: none;
    font-size: 20px;
    color: #999;
    cursor: pointer;
}

.sizeOptions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-bottom: 16px;
}

.sizeOption {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.sizeOption:hover {
    background: #f5f5f5;
}

.sizeOption.active {
    background: #e6f7ff;
}

.sizePreview {
    background: #333;
    border-radius: 50%;
}

.sizeOption span {
    font-size: 14px;
    color: #666;
}

.customSize {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.sizeSlider {
    width: 100%;
    height: 4px;
    -webkit-appearance: none;
    background: #ddd;
    border-radius: 2px;
    outline: none;
}

.sizeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #1890ff;
    border-radius: 50%;
    cursor: pointer;
}

.customSize span {
    font-size: 14px;
    color: #666;
}