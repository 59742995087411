/* footer.css */


.footer {
    box-sizing: border-box; /* 使用边框盒模型 */
    display: flex;
    flex-direction: column; /* 垂直排列 */
    justify-content: center; /* 将内容推到底部 */
    align-items: center; /* 水平居中 */
    padding: 10px 20px;
    /*background: none; !* 设置背景为无 *!*/
    background-color: transparent;  /* 设置背景为透明 */
    /*color: rgba(202, 25, 25, 0.5);*/
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%; /* 宽度为100% */
    left: 0; /* 确保从左侧开始 */
}

.musicContainer {
    display: flex; /* 使用flex布局 */
    justify-content: flex-start; /* 左对齐 */
    width: 100%; /* 宽度为100% */
}


.footerContent {
    text-align: center; /* 文本居中 */
    color: rgba(255, 255, 255, 0.3);
    margin-top: auto; /* 将内容推到底部 */
}

.footerContent a {
    color: rgba(255, 255, 255, 0.3); /* 设置链接颜色 */
    text-decoration: none; /* 移除下划线 */
}

.footerContent a:hover {
    color: #666; /* 鼠标悬停时的颜色 */
    text-decoration: underline; /* 鼠标悬停时显示下划线 */
}