.modalOpen {
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    /*width: 100%;*/
    /*height: 100%*/
    background-color: #000000; 
}

.modalOpen {
    overflow: hidden;
}

.centerModal {
    display: flex; /* 使用flex布局 */
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.modalContent {
    background-color: rgba(255, 255, 255, 0); /* 半透明背景 */
    border: 1px solid #6c757d; /* 初始灰色边框 */
    backdrop-filter: blur(1px); /* 模糊效果 */
    border-radius: 8px;
    width: 60%; /* 宽度为首页宽度的66% */
    height: 50%; /* 高度为首页高度的66% */
    overflow-y: auto; /* 如果内容超出高度则滚动 */
}

.centerRegister {
    display: flex; /* 使用flex布局 */
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.registerContent {
    background-color: rgba(255, 255, 255, 0); /* 半透明背景 */
    /*border: 1px solid #6c757d; !* 初始灰色边框 *!*/
    backdrop-filter: blur(1px); /* 模糊效果 */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /*width: 22%; !* 宽度为首页宽度的33% *!*/
    height: 30%; /* 高度为首页高度的66% */
    /*overflow-y: auto; !* 如果内容超出高度则滚动 *!*/
}

.centerMenu {
    display: flex; /* 使用flex布局 */
    justify-content: right;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 33%;*/
    z-index: 1000;
}

.menuContent {
    background-color: rgba(255, 255, 255, 0); /* 半透明背景 */
    backdrop-filter: blur(1px); /* 模糊效果 */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /*height: 30%; !* 高度为首页高度的66% *!*/
    overflow-y: auto; /* 如果内容超出高度则滚动 */
    /*border: 1px solid #6c757d;*/
}

